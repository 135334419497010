/* ---------------------------------------------------------------------------------------- background */
.bg-white {
    background-color: white;
}

.bg-orange {
    background-color: #F67C00;
}

.bg-orange-2 {
    background-color: #FFA03F;
}

.bg-brown {
    background-color: #733C00;
}

.bg-brown-2 {
    background-color: #93612D;
}

.bg-brown-3 {
    background-color: #B24F00;
}

.bg-green {
    background-color: #00a65a;
}

.bg-green-light {
    background-color: #A3A60F;
}

.bg-green-light-2 {
    background-color: #BDD631 !important;
}

.bg-green-dark {
    background-color: #006C68;
}

.bg-green-dark-2 {
    background-color: #42928F;
}

.bg-green-grey {
    background-color: #AA9;
}

.bg-red {
    background-color: #E1480B;
}

.bg-red-light {
    background-color: #FFA784;
}

.bg-red-dark {
    background-color: #AE3302;
}

.bg-blue {
    background-color: #2A6DA1;
}

.bg-blue-light {
    background-color: #22BBEE;
}

.bg-blue-grey {
    background-color: #9AB;
}

.bg-grey {
    background-color: #888;
}

.bg-female {
    background-color: #F84F00;
}

.bg-male {
    background-color: #F67C00;
}

.bg-group {
    background-color: #006C68;
}

.bg-violet {
    background-color: #6644aa;
}

.bg-violet:hover,
.bg-green-dark-2:hover {
    color: white !important;
}

/* ---------------------------------------------------------------------------------------- text */
.txt-white {
    color: white;
}

.txt-orange {
    color: #F67C00;
}

.txt-orange-2 {
    color: #FFA03F;
}

.txt-brown {
    color: #733C00;
}

.txt-brown-2 {
    color: #93612D;
}

.txt-brown-3 {
    color: #B24F00;
}

.txt-green-light {
    color: #A3A60F;
}

.txt-green-light-2 {
    color: #BDD631;
}

.txt-green-dark {
    color: #006C68;
}

.txt-green-dark-2 {
    color: #42928F;
}

.txt-red {
    color: #E1480B;
}

.txt-red-light {
    color: #FFA784;
}

.txt-red-light {
    color: #FFA784;
}

.txt-red-light {
    color: #FFA784;
}

.txt-red-dark {
    color: #AE3302;
}

.txt-blue {
    color: #2A6DA1;
}

.txt-blue-light {
    color: #22BBEE;
}

.txt-blue-grey {
    color: #9AB;
}

.txt-grey {
    color: #888;
}